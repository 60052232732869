import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import { rhythm } from "../utils/typography"

function Nav() {
    return (
        <StaticQuery
         query={navQuery}
         render={data => {
             return (
                 <div style={{
                     display: `flex`,
                     flexDirection: `row`,
                     justifyContent: `center`,
                     alignItems: `center`,
                     marginBottom: rhythm(1),
                     textAlign: `center`,
                 }}>
                     <div>
                         <p style={{ fontSize: 40, marginBottom: 0 }}>&#123;</p>
                     </div>
                     <div style={{
                         flexBasis: `20%`
                     }}>
                         <Link style={{ boxShadow: `none` }} to={`/`}>
                             Home
                        </Link>
                     </div>
                     <div style={{
                         flexBasis: `20%`
                     }}>
                        <Link style={{ boxShadow: `none` }} to={`/about`}>
                             About
                        </Link>
                     </div>
                     <div style={{
                         flexBasis: `20%`
                     }}>
                         <Link style={{ boxShadow: `none` }} to={`/now`}>
                             Now
                        </Link>
                     </div>
                     <div>
                        <p style={{ fontSize: 40, marginBottom: 0 }}>&#125;</p>
                     </div>
                 </div>
                )
            }}
        />
    )
}

const navQuery = graphql`
  query navQuery {
    resume: file(absolutePath: { regex: "/resume.pdf/" }) {
        publicURL
    }
    site {
      siteMetadata {
        resumeUrl
      }
    }
  }
`

export default Nav