/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare, faLinkedin, faGithubSquare } from '@fortawesome/free-brands-svg-icons'

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              alignItems: `center`,
              marginBottom: rhythm(2.5),
            }}
          >
            <div>
              <Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author}
                style={{
                  marginRight: rhythm(1 / 2),
                  marginBottom: 0,
                  minWidth: 100,
                  borderRadius: `100%`,
                }}
                imgStyle={{
                  borderRadius: `50%`,
                }}
              />
            </div>
            <div
              style={{
                textAlign: `center`,
              }}>
              <p style={{ marginBottom: 10 }}>
                Debatably pointless thoughts pointlessly debated by <strong>{author}</strong>.
                {` `}
                He also uses other platforms to similar effect: 
                {` `}
              </p>
            </div>
            <div
              style={{
                textAlign: `center`,
                flexDirection: `row`,
                justifyContent: `space-between`,
                display: 'flex',
                minWidth: `100px`,
              }}>
              <a href={`https://twitter.com/${social.twitter}`}
                style={{ 
                  textDecoration: `none`, 
                  backgroundImage: `none`, 
                  }}>
                <FontAwesomeIcon icon={faTwitterSquare} size="lg" />
              </a>
              {` `}
              <a href={`https://linkedin.com/in/${social.linkedin}`}
                style={{ 
                  textDecoration: `none`, 
                  backgroundImage: `none`,
                  }}>
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
              {` `}
              <a href={`https://github.com/${social.github}`}
                style={{ 
                  textDecoration: `none`, 
                  backgroundImage: `none`,
                  }}>
                <FontAwesomeIcon icon={faGithubSquare} size="lg" />
              </a>
            </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          linkedin
          github
        }
      }
    }
  }
`

export default Bio
